interface TimelineProps {
  data: Array<{ name: string; description: string; date: string; dateTime: string; }>
}

export default function Timeline({ data }: TimelineProps) {
  return (
    <div className="bg-transparent py-12">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto grid max-w-3xl grid-cols-1 gap-x-8 overflow-hidden lg:mx-0 lg:max-w-none lg:grid-cols-3">
          {data.map((item) => (
            <div className="lg:mt-0 mt-10" key={item.name}>
              <time
                dateTime={item.dateTime}
                className="flex items-center text-sm font-semibold leading-6 text-warning"
              >
                <svg
                  viewBox="0 0 4 4"
                  className="mr-4 h-1 w-1 flex-none"
                  aria-hidden="true"
                >
                  <circle cx={2} cy={2} r={2} fill="currentColor" />
                </svg>
                {item.date}
                <div
                  className="absolute -ml-2 h-px w-screen -translate-x-full bg-warning sm:-ml-4 lg:static lg:-mr-6 lg:ml-8 lg:w-auto lg:flex-auto lg:translate-x-0"
                  aria-hidden="true"
                />
              </time>
              <p className="mt-4 text-lg font-semibold leading-8 tracking-tight text-white text-left">
                {item.name}
              </p>
              <p className="mt-1 text-base leading-7 text-light text-left">
                {item.description}
              </p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
