import { MagnifyingGlassIcon, RocketLaunchIcon, WrenchScrewdriverIcon } from "@heroicons/react/24/solid";
import SectionHeader from "../layout/SectionHeader";
import Card from "../layout/Card";
import SimpleCallToAction from "../cta/SimpleCallToAction";

export interface Step {
  name: string;
  description: string;
  body: "",
  icon: React.ComponentType<React.SVGProps<SVGSVGElement>>;
  colour: string;
  scale: number;
}

export const processSteps: Step[] = [
  {
    name: "1. Discovery",
    description: "Using our proven prototyping framework you'll have a specialised designer assigned to conduct a discovery session with you.",
    body: "",
    icon: MagnifyingGlassIcon,
    colour: "danger",
    scale: 110,
  },
  {
    name: "2. Build",
    description: "Using our AI automation tools & expertise, we rapidly build your app within a fraction of the traditional time and cost.",
    body: "",
    icon: WrenchScrewdriverIcon,
    colour: "warning",
    scale: 110,
  },
  {
    name: "3. Launch",
    description: "We deploy your app to the cloud, provide you with web access and support your successful launch with any final updates & adjustments needed.",
    body: "",
    icon: RocketLaunchIcon,
    colour: "success",
    scale: 110,
  }
]

export default function Process() {
  return (
    <div className="relative isolate px-6 lg:px-8">
      <div className="mx-auto max-w-3xl">
        <SectionHeader
          alignment="center"
          header="How it works"
          subheader="Want to know our secret? It's as easy as 3 simple steps"
        />

        {/* Steps */}
        <div className="mx-auto my-16 grid max-w-2xl grid-cols-1 gap-6 sm:my-20 lg:mx-0 lg:max-w-none lg:grid-cols-3 lg:gap-8">
          {processSteps.map((process: Step) => (
            <Card
              key={process.name}
              className={`flex gap-x-4 rounded-xl bg-white/5 p-6 ring-1 ring-inset ring-white/10 transition ease-in-out delay-100 duration-300 hover:bg-${process.colour}/50 hover:scale-${process.scale}`}
            >
              <process.icon aria-hidden="true" className={`h-7 w-5 flex-none text-${process.colour}`} />
              <div className="text-base leading-7">
                <h3 className="font-semibold text-white">{process.name}</h3>
                <p className="mt-2 text-light">{process.description}</p>
              </div>
            </Card>
          ))}
        </div>

        <SimpleCallToAction
          title="Want to know more?"
          subtitle="See a detailed breakdown of our process"
          textColour="dark"
          bgColour="warning"
          buttonText="Learn more"
          buttonLink="/process"
          buttonColour="dark"
        />

      </div>
    </div>
  );
}
