import { CurrencyPoundIcon } from "@heroicons/react/24/solid";
import UnauthorisedTemplate from "../app/UnauthorisedTemplate";
import SectionHeader from "../layout/SectionHeader";
import SimpleCallToAction from "../cta/SimpleCallToAction";
import PricingComparison from "./PricingComparison";

export default function PricingPage() {
  return (
    <UnauthorisedTemplate>
      <div className="relative isolate px-6 lg:px-8 pt-32">
        <div className="mx-auto max-w-3xl grid grid-cols-1 sm:gap-y-56 gap-y-40">

          {/* Hero */}
          <span>
            <SectionHeader
              alignment="center"
              header="Pricing"
              subheader="Depending on your requirements, we have pricing plans to suit all budgets. For a custom quote, get in touch today."
            />
            <CurrencyPoundIcon className="mx-auto mt-8 sm:h-48 h-32 w-auto text-white" />
          </span>

          {/* Pricing comparison */}
          <div>
            <PricingComparison />

            {/* Call to action */}
            <div className="mt-20 text-left">
              <SimpleCallToAction
                title="Found the right plan?"
                subtitle="Let us know today and book your free consultation"
                textColour="dark"
                bgColour="info"
                buttonText="Let's get going"
                buttonLink="/contact"
                buttonColour="dark"
                buttonTextColour="white"
              />
            </div>

          </div>
        </div>

      </div>
    </UnauthorisedTemplate >
  );
}
