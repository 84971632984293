import SectionHeader from "../layout/SectionHeader";
import ContactButton from "./ContactButton";


export default function Contact() {

  return (
    <div className="relative isolate px-6 lg:px-8">
      <div className="mx-auto max-w-3xl">
        <SectionHeader
          alignment="center"
          header="Get in touch!"
          subheader="We're waiting to help you with any queries"
        />

        <ContactButton />
      </div>
    </div>
  );
}
