import { useState } from "react";
import { Dialog, DialogPanel } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";

const navigation = [
  { name: "How it works", href: "/process" },
  { name: "Value", href: "/value" },
  { name: "Pricing", href: "/pricing" },
  { name: "Contact", href: "/contact" },
  { name: "AI - coming soon!", href: "/ai" },
];

export default function Navbar() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  return (
    <header className="bg-dark bg-opacity-90 border-b-2 border-white sticky top-0 z-40">
      <nav
        className="mx-auto flex max-w-7xl items-center justify-between gap-x-6 p-6 lg:px-8"
        aria-label="Global"
      >

        {/* Logo */}
        <div className="flex lg:flex-1">
          <a href="/" className="-m-1.5">
            <span className="sr-only">Launchpad</span>
            <img
              className="h-14 w-auto border-2 border-white rounded-md"
              src="/img/logo/logo.svg"
              alt=""
            />
          </a>
        </div>

        {/* Navigation links */}
        <div className="hidden lg:flex lg:gap-x-12">
          {navigation.map((item) => (
            <a
              key={item.name}
              href={item.href}
              className="text-sm font-semibold leading-6 text-white"
            >
              {item.name}
            </a>
          ))}
        </div>

        {/* Call to action button */}
        <div className="flex flex-1 items-center justify-end gap-x-6">
          <a
            href="/contact"
            className={`rounded-md bg-gradient-to-r from-warning-light via-warning to-warning-dark background-animate px-3.5 py-2.5 text-sm font-semibold text-dark shadow-sm transition ease-in-out delay-100 duration-300 hover:scale-110 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-warning`}
          >
            Get in touch!
          </a>
        </div>

        {/* Mobile menu button */}
        <div className="flex lg:hidden">
          <button
            type="button"
            className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-white"
            onClick={() => setMobileMenuOpen(true)}
          >
            <span className="sr-only">Open main menu</span>
            <Bars3Icon className="h-6 w-6" aria-hidden="true" />
          </button>
        </div>
      </nav>

      {/* Mobile menu */}
      <Dialog
        className="lg:hidden"
        open={mobileMenuOpen}
        onClose={setMobileMenuOpen}
      >
        <div className="fixed inset-0 z-50" />
        <DialogPanel className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-dark px-6 py-6 sm:max-w-sm sm:ring-2 sm:ring-white">
          <div className="flex items-center gap-x-6">

            {/* Logo */}
            <a href="/" className="-m-1.5 p-1.5">
              <span className="sr-only">Launchpad</span>
              <img
                className="h-14 w-auto border-2 border-white rounded-md"
                src="/img/logo/logo.svg"
                alt=""
              />
            </a>

            {/* Call to action */}
            <a
              href="/contact"
              className={`ml-auto rounded-md bg-gradient-to-r from-warning-light via-warning to-warning-dark background-animate px-3.5 py-2.5 text-sm font-semibold text-dark shadow-sm transition ease-in-out delay-100 duration-300 hover:scale-110 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-warning`}
            >
              Get in touch!
            </a>
            <button
              type="button"
              className="-m-2.5 rounded-md p-2.5 text-white"
              onClick={() => setMobileMenuOpen(false)}
            >
              <span className="sr-only">Close menu</span>
              <XMarkIcon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <div className="mt-6 flow-root">
            <div className="-my-6 divide-y divide-light/50">
              <div className="space-y-2 py-6">
                {navigation.map((item) => (
                  <a
                    key={item.name}
                    href={item.href}
                    className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-white hover:bg-light/20"
                  >
                    {item.name}
                  </a>
                ))}
              </div>
            </div>
          </div>
        </DialogPanel>
      </Dialog>
    </header>
  );
}
