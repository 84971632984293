
interface SectionHeaderProps {
  alignment: "center" | "left" | "right";
  header: string;
  subheader: string;
}

export default function SectionHeader({ alignment, header, subheader }: SectionHeaderProps) {
  return (
    <div className={`mx-auto lg:mx-0 text-${alignment}`}>
      <h2 className="text-4xl font-bold tracking-tight text-white sm:text-6xl">{header}</h2>
      <p className="mt-6 text-lg leading-8 text-light">
        {subheader}
      </p>
    </div>
  )
}