interface SpaceFillerProps {
  id?: string;
  className?: string;
  children: React.ReactNode;
  verticalPosition?: string;
  flex?: boolean;
  horizontalPosition?: string;
}

export default function SpaceFiller({
  id,
  className,
  children,
  verticalPosition,
  horizontalPosition,
}: SpaceFillerProps) {
  return (
    <div
      id={id}
      className={`flex h-full flex-1 justify-${horizontalPosition} items-${verticalPosition} ${className}`}
    >
      {children}
    </div>
  );
}

SpaceFiller.defaultProps = {
  id: "",
  className: "",
  verticalPosition: "center",
  horizontalPosition: "center",
};
