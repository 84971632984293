import { ArrowPathIcon } from "@heroicons/react/24/solid";
import UnauthorisedTemplate from "../app/UnauthorisedTemplate";
import SectionHeader from "../layout/SectionHeader";
import Timeline from "../stats/Timeline";
import SimpleCallToAction from "../cta/SimpleCallToAction";

export default function ProcessPage() {
  return (
    <UnauthorisedTemplate>
      <div className="relative isolate px-6 lg:px-8 pt-32">
        <div className="mx-auto max-w-3xl grid grid-cols-1 sm:gap-y-56 gap-y-40">

          {/* Hero */}
          <span>
            <SectionHeader
              alignment="center"
              header="How it works"
              subheader="We used a tried and tested framework powered by AI to deliver high quality results lightning fast. Keep reading to find out more."
            />
            <ArrowPathIcon className="mx-auto mt-8 sm:h-48 h-32 w-auto text-white" />
          </span>

          {/* 1. Discovery */}
          <div className={`mx-auto lg:mx-0 text-center`}>
            <h2 className="text-2xl font-bold tracking-tight text-white sm:text-4xl">1. Discovery</h2>
            <Timeline data={
              [
                {
                  name: "Introduction call",
                  description: "We'll organise a time to speak with you over a video call and discuss your project, needs & the services we offer.",
                  date: "1. Let's touch base",
                  dateTime: "2022-01-01"
                },
                {
                  name: "Requirements framework",
                  description: "During the call, we'll use our requirements framework to understand the technical needs to implement your project.",
                  date: "2. Let's learn more",
                  dateTime: "2022-01-01"
                },
                {
                  name: "Documentation",
                  description: "Our team will document the specification and provide you with a personalised quote, timeline and project plan.",
                  date: "3. Let's get going",
                  dateTime: "2022-01-01"
                }
              ]
            } />
          </div>

          {/* 2. Build */}
          <div className={`mx-auto lg:mx-0 text-center`}>
            <h2 className="text-2xl font-bold tracking-tight text-white sm:text-4xl">2. Build</h2>
            <Timeline data={
              [
                {
                  name: "Collect assets",
                  description: "To start building all we require is any branding assets, logos, colour schemes, fonts etc... that you require!",
                  date: "1. Let's get going",
                  dateTime: "2022-01-01"
                },
                {
                  name: "Rapid development",
                  description: "Using our AI automation tool and team of experts, we'll rapidly develop your project to the highest quality.",
                  date: "2. Let's create",
                  dateTime: "2022-01-01"
                },
                {
                  name: "Deployment",
                  description: "Once the build is complete, we'll deploy it to a cloud environment for you to review and provide feedback.",
                  date: "3. Let's deploy it",
                  dateTime: "2022-01-01"
                }
              ]
            } />
          </div>

          {/* 3. Launch */}
          <div className={`mx-auto lg:mx-0 text-center`}>
            <h2 className="text-2xl font-bold tracking-tight text-white sm:text-4xl">3. Launch</h2>
            <Timeline data={
              [
                {
                  name: "Access via URL",
                  description: "Once you're happy with the project, we'll provide you with a URL to access your project online.",
                  date: "1. Let's get it online",
                  dateTime: "2022-01-01"
                },
                {
                  name: "Demonstration",
                  description: "We'll organise a time to demonstrate the project to you and provide you with any training required.",
                  date: "2. Let's show you",
                  dateTime: "2022-01-01"
                },
                {
                  name: "Time to fly",
                  description: "Whether you're looking to gain customers, feedback or funding, you've now got your app to get started!",
                  date: "3. Let's launch!",
                  dateTime: "2022-01-01"
                }
              ]
            } />

            {/* Call to action */}
            <div className="mt-20 text-left">
              <SimpleCallToAction
                title="Ready to take the leap?"
                subtitle="Don't waste more time, get moving today"
                textColour="dark"
                bgColour="warning"
                buttonText="Let's get going"
                buttonLink="/contact"
                buttonColour="dark"
                buttonTextColour="white"
              />
            </div>
          </div>

        </div>
      </div>
    </UnauthorisedTemplate>
  );
}
