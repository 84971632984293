import { PaperAirplaneIcon } from "@heroicons/react/24/solid";


export default function ContactButton() {

  function redirectToEmail(e: any) {
    e.preventDefault();
    if (typeof window !== "undefined" && window !== null) {
      const newWindow = window.open("mailto:support@halotechlab.com", '_blank');
      if (newWindow !== null) {
        newWindow.focus();
      }
    }
  }

  return (
    <section className="border-t border-dark/20" aria-labelledby="section-9-title" id="contact">
      <h2 id="section-9-title" className="sr-only">Contact</h2>
      <div className="grid grid-cols-1 gap-4">

        {/* Form */}
        <div className="mx-auto sm:max-w-xl w-full mt-10">
          <a className="group" href="#!" onClick={(e: any) => redirectToEmail(e)} rel="noreferrer">
            <div className="p-6 rounded-2xl bg-gradient-to-r from-warning-light via-warning to-warning-dark background-animate transition ease-in-out delay-100 duration-300 hover:scale-110">
              <span className="flex justify-between text-dark font-semibold">
                <PaperAirplaneIcon className="h-6 w-6 text-dark" />
                Click here to send us an email!
              </span>
            </div>
          </a>
        </div>
      </div>
    </section>
  );
}
