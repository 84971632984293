import {
  Fragment,
  JSXElementConstructor,
  Key,
  ReactElement,
  ReactNode,
  ReactPortal,
} from "react";
import { CheckIcon, MinusIcon } from "@heroicons/react/24/outline";

interface Feature {
  name: string;
  tiers: [
    { name: "Basic"; value: null | boolean | string },
    { name: "Standard"; value: null | boolean | string },
    { name: "Premium"; value: null | boolean | string }
  ];
}

interface Section {
  name: string;
  features: Array<Feature>;
}

const pricing: any = [
  {
    tiers: [
      {
        name: "Basic",
        id: "tier-basic",
        href: "/contact",
        priceMonthly: "£500",
        description: "Here is a description of this pricing tier",
        mostPopular: false,
      },
      {
        name: "Standard",
        id: "tier-standard",
        href: "/contact",
        priceMonthly: "£1,000",
        description: "Here is a description of this pricing tier",
        mostPopular: false,
      },
      {
        name: "Premium",
        id: "tier-premium",
        href: "/contact",
        priceMonthly: "Custom",
        description: "Here is a description of this pricing tier",
        mostPopular: true,
      },
    ],
    sections: [
      {
        name: "Build",
        features: [
          {
            name: "Time to build",
            tiers: [
              { name: "Basic", value: "< 7 days" },
              { name: "Standard", value: "7 - 15 days" },
              { name: "Premium", value: "15 - 30 days" },
            ],
          },
          {
            name: "Interactive components",
            tiers: [
              { name: "Basic", value: true },
              { name: "Standard", value: true },
              { name: "Premium", value: true },
            ],
          },
          {
            name: "Data driven features",
            tiers: [
              { name: "Basic", value: false },
              { name: "Standard", value: true },
              { name: "Premium", value: true },
            ],
          },
          {
            name: "Revisions",
            tiers: [
              { name: "Basic", value: "None" },
              { name: "Standard", value: "1" },
              { name: "Premium", value: "2" },
            ],
          },
        ],
      },
      {
        name: "Design",
        features: [
          {
            name: "Fully responsive design",
            tiers: [
              { name: "Basic", value: true },
              { name: "Standard", value: true },
              { name: "Premium", value: true },
            ],
          },
          {
            name: "AI design service",
            tiers: [
              { name: "Basic", value: false },
              { name: "Standard", value: false },
              { name: "Premium", value: true },
            ],
          },
        ],
      },
      {
        name: "Deployment",
        features: [
          {
            name: "Cloud based web app",
            tiers: [
              { name: "Basic", value: true },
              { name: "Standard", value: true },
              { name: "Premium", value: true },
            ],
          },
          {
            name: "Accessible via unique URL",
            tiers: [
              { name: "Basic", value: true },
              { name: "Standard", value: true },
              { name: "Premium", value: true },
            ],
          },
        ],
      },
      {
        name: "Database",
        features: [
          {
            name: "Lightweight database & server",
            tiers: [
              { name: "Basic", value: false },
              { name: "Standard", value: true },
              { name: "Premium", value: true },
            ],
          },
          {
            name: "Exportable data",
            tiers: [
              { name: "Basic", value: false },
              { name: "Standard", value: false },
              { name: "Premium", value: true },
            ],
          },
        ],
      },
      {
        name: "Support",
        features: [
          {
            name: "Free cloud hosting for 6 months",
            tiers: [
              { name: "Basic", value: true },
              { name: "Standard", value: true },
              { name: "Premium", value: true },
            ],
          },
        ],
      },
    ],
  },
];

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

function buildFeature(feature: Feature, name: string) {
  const tiers = feature.tiers;
  const filteredTiers = tiers.filter((tier) => tier.name === name);
  if (filteredTiers.length === 0)
    return (
      <CheckIcon
        className="h-6 w-5 flex-none text-warning-dark"
        aria-hidden="true"
      />
    );
  const tier = filteredTiers[0];
  if (typeof tier.value === "string")
    return (
      <span className="text-sm leading-6 text-secondary">({tier.value})</span>
    );
  if (tier.value === true) {
    return (
      <CheckIcon
        className="h-6 w-5 flex-none text-warning-dark"
        aria-hidden="true"
      />
    );
  } else {
    return (
      <MinusIcon
        className="h-6 w-5 flex-none text-secondary"
        aria-hidden="true"
      />
    );
  }
}

function buildFeatureLarge(feature: Feature, name: string) {
  const tiers = feature.tiers;
  const filteredTiers = tiers.filter((tier) => tier.name === name);
  if (filteredTiers.length === 0) return null;
  const tier = filteredTiers[0];
  if (tier.value === null) return null;
  if (typeof tier.value === "string")
    return (
      <div className="text-center text-sm leading-6 text-secondary">
        {tier.value}
      </div>
    );
  if (tier.value === true) {
    return (
      <>
        <CheckIcon
          className="mx-auto h-5 w-5 text-warning-dark"
          aria-hidden="true"
        />
        <span className="sr-only">{`Included in ${tier.name}`}</span>
      </>
    );
  } else {
    return (
      <>
        <MinusIcon
          className="mx-auto h-5 w-5 text-secondary"
          aria-hidden="true"
        />
        <span className="sr-only">{`Not included in ${tier.name}`}</span>
      </>
    );
  }
}

export default function PricingComparison() {
  return (
    <div className="bg-transparent">
      <div className="mx-auto">

        {pricing.map((obj: any, idx: number) => (
          <div key={idx} id="pricing-sections">
            {/* xs to lg */}
            <div className="mx-auto mt-8 max-w-md space-y-8 sm:mt-10 lg:hidden">
              {obj.tiers.map((tier: any) => (
                <section
                  key={tier.id}
                  className={classNames(
                    tier.mostPopular ? "rounded-xl bg-light/50" : "",
                    "p-8"
                  )}
                >
                  <h3
                    id={tier.id}
                    className="text-sm font-semibold leading-6 text-white"
                  >
                    {tier.name}
                  </h3>
                  <span className="mt-2 text-xs text-light">
                    Starting from
                  </span>
                  <p className="flex items-baseline gap-x-1 text-white">
                    <span className="text-4xl font-bold">
                      {tier.priceMonthly}
                    </span>
                  </p>
                  <a
                    href={tier.href}
                    aria-describedby={tier.id}
                    className={classNames(
                      tier.mostPopular
                        ? "bg-gradient-to-r from-warning-light via-warning to-warning-dark background-animate text-dark"
                        : "text-secondary ring-1 ring-inset ring-secondary hover:bg-light/10",
                      "mt-8 block rounded-md py-2 px-3 text-center text-sm font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-warning"
                    )}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Get in touch
                  </a>
                  <ul className="mt-10 space-y-4 text-sm leading-6 text-light">
                    {obj.sections.map((section: Section) => (
                      <li key={section.name}>
                        <ul className="space-y-4">
                          {section.features.map((feature: Feature) => (
                            <li key={feature.name} className="flex gap-x-3">
                              {feature.name}{" "}
                              <span className="ml-auto">
                                {buildFeature(feature, tier.name)}
                              </span>
                            </li>
                          ))}
                        </ul>
                      </li>
                    ))}
                  </ul>
                </section>
              ))}
            </div>

            {/* lg+ */}
            <div className="isolate mt-12 hidden lg:block">
              <div className="relative -mx-8">
                <table className="w-full table-fixed border-separate border-spacing-x-8 text-left">
                  <caption className="sr-only">Pricing plan comparison</caption>
                  <colgroup>
                    <col className="w-1/4" />
                    <col className="w-1/4" />
                    <col className="w-1/4" />
                    <col className="w-1/4" />
                  </colgroup>
                  <thead>
                    <tr>
                      <td />
                      {obj.tiers.map(
                        (tier: {
                          id: Key | null | undefined;
                          name:
                          | string
                          | number
                          | boolean
                          | ReactElement<
                            any,
                            string | JSXElementConstructor<any>
                          >
                          | Iterable<ReactNode>
                          | ReactPortal
                          | null
                          | undefined;
                        }) => (
                          <th
                            key={tier.id}
                            scope="col"
                            className="px-6 pt-6 xl:px-8 xl:pt-8"
                          >
                            <div className="text-sm font-semibold leading-7 text-white">
                              {tier.name}
                            </div>
                          </th>
                        )
                      )}
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th scope="row">
                        <span className="sr-only">Price</span>
                      </th>
                      {obj.tiers.map(
                        (tier: {
                          id: Key | null | undefined;
                          priceMonthly:
                          | string
                          | number
                          | boolean
                          | ReactElement<
                            any,
                            string | JSXElementConstructor<any>
                          >
                          | Iterable<ReactNode>
                          | ReactPortal
                          | null
                          | undefined;
                          href: string | undefined;
                          mostPopular: any;
                        }) => (
                          <td key={tier.id} className="px-6 pt-2 xl:px-8">
                            <span className="text-xs text-light">
                              Starting from
                            </span>
                            <div className="flex items-baseline gap-x-1 text-white">
                              <span className="text-4xl font-bold">
                                {tier.priceMonthly}
                              </span>
                            </div>
                            <a
                              href={tier.href}
                              className={classNames(
                                tier.mostPopular
                                  ? "bg-gradient-to-r from-warning-light via-warning to-warning-dark background-animate warning text-dark"
                                  : "text-secondary ring-1 ring-inset ring-secondary hover:bg-light/10",
                                "mt-8 block rounded-md py-2 px-3 text-center text-sm font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-warning-dark"
                              )}
                              target="_blank"
                              rel="noreferrer"
                            >
                              Get in touch
                            </a>
                          </td>
                        )
                      )}
                    </tr>
                    {obj.sections.map((section: any, sectionIdx: number) => (
                      <Fragment key={section.name}>
                        <tr>
                          <th
                            scope="colgroup"
                            colSpan={4}
                            className={classNames(
                              sectionIdx === 0 ? "pt-8" : "pt-16",
                              "pb-4 text-sm font-semibold leading-6 text-white"
                            )}
                          >
                            {section.name}
                            <div className="absolute inset-x-8 mt-4 h-px bg-secondary/30" />
                          </th>
                        </tr>
                        {section.features.map((feature: Feature) => (
                          <tr key={feature.name}>
                            <th
                              scope="row"
                              className="py-4 text-sm font-normal leading-6 text-light"
                            >
                              {feature.name}
                              <div className="absolute inset-x-8 mt-4 h-px bg-secondary/10" />
                            </th>
                            {obj.tiers.map(
                              (tier: {
                                id: Key | null | undefined;
                                name: string;
                              }) => (
                                <td key={tier.id} className="px-6 py-4 xl:px-8">
                                  {buildFeatureLarge(feature, tier.name)}
                                </td>
                              )
                            )}
                          </tr>
                        ))}
                      </Fragment>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
