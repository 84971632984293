import { ArrowRightCircleIcon } from "@heroicons/react/24/solid";

interface SimpleCallToActionProps {
  title: string;
  subtitle: string;
  textColour: string;
  bgColour: string;
  buttonText: string;
  buttonLink: string;
  buttonColour: string
  buttonTextColour?: string;
}


export default function SimpleCallToAction({ title, subtitle, textColour, bgColour, buttonText, buttonLink, buttonColour, buttonTextColour = "white" }: SimpleCallToActionProps) {
  return (
    <div className={`bg-gradient-to-r from-${bgColour}-light via-${bgColour} to-${bgColour}-dark background-animate rounded-xl`}>
      <div className="mx-auto max-w-7xl px-6 py-12 sm:py-12 lg:flex lg:items-center lg:justify-between lg:px-8">
        <span>
          <h2 className={`text-3xl font-bold tracking-tight text-${textColour} sm:text-4xl`}>
            {title}
            <br />
          </h2>
          <p className={`text-lg text-${textColour}`}>{subtitle}</p>
        </span>

        <div className="mt-10 flex items-center gap-x-6 lg:mt-0 lg:flex-shrink-0">
          <a
            href={buttonLink}
            className={`rounded-md bg-${buttonColour} px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm transition ease-in-out delay-100 duration-300 hover:scale-110 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-${buttonColour}`}
          >
            <span className={`flex text-${buttonTextColour}`}>
              {buttonText}
              <ArrowRightCircleIcon className="h-5 w-5 ml-2" aria-hidden="true" />
            </span>
          </a>
        </div>
      </div>
    </div>

  );
}
