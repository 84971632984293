import { CheckBadgeIcon, CheckCircleIcon, ClipboardDocumentCheckIcon, CreditCardIcon, FaceSmileIcon, HandRaisedIcon, LifebuoyIcon, RocketLaunchIcon, ScaleIcon, SparklesIcon } from "@heroicons/react/24/solid";
import UnauthorisedTemplate from "../app/UnauthorisedTemplate";
import SectionHeader from "../layout/SectionHeader";
import SimpleCallToAction from "../cta/SimpleCallToAction";

export default function ValuePage() {
  return (
    <UnauthorisedTemplate>
      <div className="relative isolate px-6 lg:px-8 pt-32">
        <div className="mx-auto max-w-4xl grid grid-cols-1 sm:gap-y-56 gap-y-40">

          {/* Hero */}
          <span>
            <SectionHeader
              alignment="center"
              header="Value proposition"
              subheader="We provide a unique service that fills the current gap in the market for entrepreneurs and startups. Keep reading to find out more."
            />
            <SparklesIcon className="mx-auto mt-8 sm:h-48 h-32 w-auto text-white" />
          </span>

          {/* 1. Cost & time saving */}
          <div className={`mx-auto lg:mx-0 text-left`}>
            <h2 className="text-2xl font-bold tracking-tight text-white sm:text-4xl text-center">Cost & time saving</h2>
            <div className="mx-auto my-16 flex max-w-3xl flex-col gap-8 lg:mx-0 lg:my-20 lg:max-w-none lg:flex-row lg:items-end">
              {/* Validation - FAST */}
              <div className="group flex flex-col-reverse justify-between gap-x-16 gap-y-8 rounded-2xl bg-light/5 ring-1 ring-inset ring-white/10 transition ease-in-out delay-100 duration-300 hover:bg-black p-8 sm:flex-row-reverse sm:items-end w-full lg:w-56 lg:max-w-none lg:flex-none lg:flex-col lg:items-start lg:gap-y-16">
                <p className="flex-none text-3xl font-bold tracking-tight text-white">
                  Quick validation
                  <CheckCircleIcon className="flex-none h-14 w-auto text-white group-hover:text-success mt-4" />
                </p>
                <div className="sm:w-80 sm:shrink lg:w-auto lg:flex-none">
                  <p className="text-lg font-semibold tracking-tight text-white">
                    {`Validate your concept in days`}
                  </p>
                  <p className="mt-2 text-base leading-7 text-light">
                    As an early stage business, you need to know if your idea is viable as quickly as possible. We can help you do that in a matter of days, not months.
                  </p>
                </div>
              </div>

              {/* Speed to market */}
              <div className="group flex flex-col-reverse justify-between gap-x-16 gap-y-8 rounded-2xl bg-light/5 ring-1 ring-inset ring-white/10 transition ease-in-out delay-100 duration-300 hover:bg-black p-8 sm:flex-row-reverse sm:items-end lg:w-72 lg:max-w-none lg:flex-none lg:flex-col lg:items-start lg:gap-y-20">
                <p className="flex-none text-3xl font-bold tracking-tight text-white">
                  Speed to market
                  <RocketLaunchIcon className="flex-none h-14 w-auto text-white group-hover:text-warning mt-4" />
                </p>
                <div className="sm:w-80 sm:shrink lg:w-auto lg:flex-none">
                  <p className="text-lg font-semibold tracking-tight text-white">
                    {`> 90% reduction in time to market`}
                  </p>
                  <p className="mt-2 text-base leading-7 text-light">
                    In a world where technology moves so fast, you can't afford to wait months for launch. We can help you get to market in a fraction of the time using AI powered automation tools.
                  </p>
                </div>
              </div>

              {/* Affordable prices tailored to you */}
              <div className="group flex flex-col-reverse justify-between gap-x-16 gap-y-8 rounded-2xl bg-light/5 ring-1 ring-inset ring-white/10 transition ease-in-out delay-100 duration-300 hover:bg-black p-8 sm:flex-row-reverse sm:items-end w-full lg:max-w-sm lg:flex-auto lg:flex-col lg:items-start lg:gap-y-40">
                <p className="flex-none text-3xl font-bold tracking-tight text-white">
                  Affordable prices
                  <CreditCardIcon className="flex-none h-14 w-auto text-white group-hover:text-primary mt-4" />
                </p>
                <div className="sm:w-80 sm:shrink lg:w-auto lg:flex-none">
                  <p className="text-lg font-semibold tracking-tight text-white">
                    Prices tailored to your budget
                  </p>
                  <p className="mt-2 text-base leading-7 text-light">
                    We've been in your shoes, we understand your challenges. We're here to help you launch, not bleed you dry! We provide tailored prices to suit your budget.
                  </p>
                </div>
              </div>

            </div>
          </div>

          {/* 2. Technical expertise */}
          <div className={`mx-auto lg:mx-0 text-left`}>
            <h2 className="text-2xl font-bold tracking-tight text-white sm:text-4xl text-center">Technical expertise</h2>
            <div className="mx-auto my-16 flex max-w-3xl flex-col gap-8 lg:mx-0 lg:my-20 lg:max-w-none lg:flex-row lg:items-end">
              {/* Wide reach of expertise */}
              <div className="group flex flex-col-reverse justify-between gap-x-16 gap-y-8 rounded-2xl bg-light/5 ring-1 ring-inset ring-white/10 transition ease-in-out delay-100 duration-300 hover:bg-black p-8 sm:flex-row-reverse sm:items-end w-full lg:w-56 lg:max-w-none lg:flex-none lg:flex-col lg:items-start">
                <p className="flex-none text-3xl font-bold tracking-tight text-white">
                  Domain experts
                  <CheckBadgeIcon className="flex-none h-14 w-auto text-white group-hover:text-success mt-4" />
                </p>
                <div className="sm:w-80 sm:shrink lg:w-auto lg:flex-none">
                  <p className="text-lg font-semibold tracking-tight text-white">
                    {`In AI, ML, Cloud, Mobile & more...`}
                  </p>
                  <p className="mt-2 text-base leading-7 text-light">
                    You may not have the technical expertise in-house, but we do. We have a wide range of experts in AI, ML, Cloud, Mobile and more to help you build your product.
                  </p>
                </div>
              </div>

              {/* Years of experience */}
              <div className="group flex flex-col-reverse justify-between gap-x-16 gap-y-8 rounded-2xl bg-light/5 ring-1 ring-inset ring-white/10 transition ease-in-out delay-100 duration-300 hover:bg-black p-8 sm:flex-row-reverse sm:items-end lg:w-72 lg:max-w-none lg:flex-none lg:flex-col lg:items-start lg:gap-y-36">
                <p className="flex-none text-3xl font-bold tracking-tight text-white">
                  Decades of experience
                  <ClipboardDocumentCheckIcon className="flex-none h-14 w-auto text-white group-hover:text-primary mt-4" />
                </p>
                <div className="sm:w-80 sm:shrink lg:w-auto lg:flex-none">
                  <p className="text-lg font-semibold tracking-tight text-white">
                    {`On 100's of projects`}
                  </p>
                  <p className="mt-2 text-base leading-7 text-light">
                    We've been around the block a few times. We've worked on 100's of projects and have the experience to help you build your product quickly and efficiently.
                  </p>
                </div>
              </div>

              {/* Agnostic */}
              <div className="group flex flex-col-reverse justify-between gap-x-16 gap-y-8 rounded-2xl bg-light/5 ring-1 ring-inset ring-white/10 transition ease-in-out delay-100 duration-300 hover:bg-black p-8 sm:flex-row-reverse sm:items-end w-full lg:max-w-sm lg:flex-auto lg:flex-col lg:items-start lg:gap-y-16">
                <p className="flex-none text-3xl font-bold tracking-tight text-white">
                  Non-biased approach
                  <ScaleIcon className="flex-none h-14 w-auto text-white group-hover:text-info mt-4" />
                </p>
                <div className="sm:w-80 sm:shrink lg:w-auto lg:flex-none">
                  <p className="text-lg font-semibold tracking-tight text-white">
                    We'll build what's right for you
                  </p>
                  <p className="mt-2 text-base leading-7 text-light">
                    We're not tied to any technology or platform. We'll build what's right for you, not what's right for us.
                  </p>
                </div>
              </div>

            </div>
          </div>

          {/* 3. A trusted advisor */}
          <div className={`mx-auto lg:mx-0 text-left`}>

            <h2 className="text-2xl font-bold tracking-tight text-white sm:text-4xl text-center">A trusted advisor</h2>
            <div className="mx-auto my-16 flex max-w-3xl flex-col gap-8 lg:mx-0 lg:my-20 lg:max-w-none lg:flex-row lg:items-end">
              {/* Been in your shoes, empathy */}
              <div className="group flex flex-col-reverse justify-between gap-x-16 gap-y-8 rounded-2xl bg-light/5 ring-1 ring-inset ring-white/10 transition ease-in-out delay-100 duration-300 hover:bg-black p-8 sm:flex-row-reverse sm:items-end w-full lg:w-56 lg:max-w-none lg:flex-none lg:flex-col lg:items-start lg:gap-y-16">
                <p className="flex-none text-3xl font-bold tracking-tight text-white">
                  Empathy
                  <HandRaisedIcon className="flex-none h-14 w-auto text-white group-hover:text-info mt-4" />
                </p>
                <div className="sm:w-80 sm:shrink lg:w-auto lg:flex-none">
                  <p className="text-lg font-semibold tracking-tight text-white">
                    {`We've been in your shoes`}
                  </p>
                  <p className="mt-2 text-base leading-7 text-light">
                    If you're bootstrapping or self-funding, we've been there. We understand your needs, challenges and can help you navigate the startup world.
                  </p>
                </div>
              </div>

              {/* Local resources */}
              <div className="group flex flex-col-reverse justify-between gap-x-16 gap-y-8 rounded-2xl bg-light/5 ring-1 ring-inset ring-white/10 transition ease-in-out delay-100 duration-300 hover:bg-black p-8 sm:flex-row-reverse sm:items-end lg:w-72 lg:max-w-none lg:flex-none lg:flex-col lg:items-start lg:gap-y-20">
                <p className="flex-none text-3xl font-bold tracking-tight text-white">
                  A friendly face
                  <FaceSmileIcon className="flex-none h-14 w-auto text-white group-hover:text-danger mt-4" />
                </p>
                <div className="sm:w-80 sm:shrink lg:w-auto lg:flex-none">
                  <p className="text-lg font-semibold tracking-tight text-white">
                    {`Local, friendly & approachable`}
                  </p>
                  <p className="mt-2 text-base leading-7 text-light">
                    We're not a faceless corporation. We're local, friendly and approachable - based in the UK. We're here to help you succeed.
                  </p>
                </div>
              </div>

              {/* Advisory approach, not to take your money */}
              <div className="group flex flex-col-reverse justify-between gap-x-16 gap-y-8 rounded-2xl bg-light/5 ring-1 ring-inset ring-white/10 transition ease-in-out delay-100 duration-300 hover:bg-black p-8 sm:flex-row-reverse sm:items-end w-full lg:max-w-sm lg:flex-auto lg:flex-col lg:items-start lg:gap-y-40">
                <p className="flex-none text-3xl font-bold tracking-tight text-white">
                  Always advisory
                  <LifebuoyIcon className="flex-none h-14 w-auto text-white group-hover:text-success mt-4" />
                </p>
                <div className="sm:w-80 sm:shrink lg:w-auto lg:flex-none">
                  <p className="text-lg font-semibold tracking-tight text-white">
                    We're here to help, not take your money
                  </p>
                  <p className="mt-2 text-base leading-7 text-light">
                    Many developers will be happy to take your money and build what you ask for. We're here to help you build what you need, not what you think you need.
                  </p>
                </div>
              </div>

            </div>

            {/* Call to action */}
            <div className="mt-20 text-left">
              <SimpleCallToAction
                title="Does this sound good?"
                subtitle="Get moving today, we're here to help"
                textColour="dark"
                bgColour="success"
                buttonText="Let's go"
                buttonLink="/contact"
                buttonColour="dark"
                buttonTextColour="white"
              />
            </div>

          </div>

        </div>
      </div>
    </UnauthorisedTemplate >
  );
}
