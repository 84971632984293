import Banner from "../layout/Banner";
import SpaceFiller from "../layout/SpaceFiller";
import Navbar from "../landing/Navbar";
import FooterWithMission from "../footer/FooterWithMission";

export default function UnauthorisedTemplate({
  children,
}: {
  children: React.ReactNode;
}) {
  return (
    <div id="start">
      <Navbar />
      <SpaceFiller className="bg-dark" verticalPosition="start">
        <main className="grid grid-cols-1 sm:gap-y-72 gap-y-48">{children}</main>

        {/* Alert banner */}
        <Banner />
      </SpaceFiller>

      {/* Footer */}
      <FooterWithMission />
    </div>
  );
}
