interface CardProps {
  colour?: string;
  children: React.ReactNode;
  className?: string;
  paddingX?: number;
  paddingY?: number;
}

export default function Card({
  colour,
  children,
  className,
  paddingX,
  paddingY,
}: CardProps) {
  return (
    <div
      className={`bg-${colour} rounded-lg px-${paddingX} py-${paddingY} overflow-x-auto drop-shadow scrollbar-invisible ${className}`}
    >
      {children}
    </div>
  );
}

Card.defaultProps = {
  colour: "white",
  className: "",
  paddingX: 4,
  paddingY: 4,
};
