import { CheckIcon } from "@heroicons/react/20/solid";
import SectionHeader from "../layout/SectionHeader";
import SimpleCallToAction from "../cta/SimpleCallToAction";

const tiers = [
  {
    name: "Basic",
    id: "tier-basic",
    href: "/contact",
    price: "£500",
    description: "Interactive, clickable prototype in days",
    features: [
      "Guaranteed build time in < 7 days",
      "Deployed to the cloud",
      "Accessible via a web URL",
      "Fully responsive design for all devices",
    ],
    featured: false,
    cta: "Buy plan!",
  },
  {
    name: "Standard",
    id: "tier-standard",
    href: "/contact",
    price: "£1,000",
    description: "Full stack web app with core functionality",
    features: [
      "Build time approximately 7 - 15 days",
      "Lightweight database & server",
      "Data driven web app",
      "Deployed to the cloud",
      "Accessible via a web URL",
      "Fully responsive design for all devices",
      "1 revision",
    ],
    featured: false,
    cta: "Buy plan!",
  },
  {
    name: "Premium",
    id: "tier-premium",
    href: "/contact",
    price: "Custom",
    description: "Advanced full stack web app with custom functionality",
    features: [
      "Build time approximately 15 - 30 days",
      "Advanced backend and frontend features",
      "Database & server",
      "Data driven web app",
      "Deployed to the cloud",
      "Accessible via a web URL",
      "Fully responsive design for all devices",
      "2 revisions",
    ],
    featured: true,
    cta: "Contact sales!",
  },
];

function classNames(...classes: any) {
  return classes.filter(Boolean).join(" ");
}

export default function PricingTiers() {

  return (
    <div className="relative isolate px-6 lg:px-8">
      <div className="mx-auto max-w-4xl">

        <SectionHeader
          alignment="center"
          header="Pricing"
          subheader="Affordable pricing for your requirements"
        />
        <p className="text-xs text-center mx-auto italic text-secondary">*All prices are "starting from" figures and exclusive of VAT</p>

        <div className="isolate mx-auto my-16 grid max-w-md grid-cols-1 gap-8 lg:mx-0 lg:max-w-none lg:grid-cols-3">
          {tiers.map((tier) => (
            <div
              key={tier.id}
              className={classNames(
                tier.featured ? "bg-gradient-to-r from-warning-light via-warning to-warning-dark background-animate" : "bg-light/5 ring-1 ring-inset ring-white/10",
                "rounded-3xl p-8 ring-1 xl:p-10"
              )}
            >
              <h3
                id={tier.id}
                className={classNames(
                  tier.featured ? "text-dark" : "text-white",
                  "text-lg font-semibold leading-8"
                )}
              >
                {tier.name}
              </h3>
              <p
                className={classNames(
                  tier.featured ? "text-dark" : "text-light",
                  "mt-4 text-sm leading-6"
                )}
              >
                {tier.description}
              </p>
              <p className="mt-6 flex items-baseline gap-x-1">
                <span
                  className={classNames(
                    tier.featured ? "text-dark" : "text-white",
                    "text-4xl font-bold tracking-tight"
                  )}
                >
                  {tier.price}
                </span>
              </p>
              <a
                href={tier.href}
                aria-describedby={tier.id}
                className={classNames(
                  tier.featured
                    ? "bg-dark text-white transition ease-in-out delay-100 duration-300 hover:scale-110 focus-visible:outline-dark"
                    : "bg-gradient-to-r from-warning-light via-warning to-warning-dark background-animate transition ease-in-out delay-100 duration-300 hover:scale-110 text-dark focus-visible:outline-warning",
                  "mt-6 block rounded-md px-3 py-2 text-center text-sm font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
                )}
              >
                {tier.cta}
              </a>
              <ul
                className={classNames(
                  tier.featured ? "text-dark" : "text-light",
                  "mt-8 space-y-3 text-sm leading-6 xl:mt-10"
                )}
              >
                {tier.features.map((feature) => (
                  <li key={feature} className="flex gap-x-3">
                    <CheckIcon
                      className={classNames(
                        tier.featured ? "text-dark" : "text-warning",
                        "h-6 w-5 flex-none"
                      )}
                      aria-hidden="true"
                    />
                    {feature}
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>

        {/* Call to action */}
        <SimpleCallToAction
          title="Not sure which plan is right for you?"
          subtitle="See the full breakdown of what's included"
          textColour="white"
          bgColour="dark"
          buttonText="Show me the pricing"
          buttonLink="/pricing"
          buttonColour="white"
          buttonTextColour="dark"
        />

      </div>
    </div>
  );
}
