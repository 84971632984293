import { CpuChipIcon } from "@heroicons/react/24/solid";
import UnauthorisedTemplate from "../app/UnauthorisedTemplate";
import SectionHeader from "../layout/SectionHeader";

export default function AiPage() {
  return (
    <UnauthorisedTemplate>
      <div className="relative isolate px-6 lg:px-8 pt-32">
        <div className="mx-auto max-w-3xl grid grid-cols-1">

          {/* Hero */}
          <span>
            <SectionHeader
              alignment="center"
              header="AI"
              subheader="AI is at the core of our business to help you launch faster! We're not stopping there, we're working on a new AI no-code service to help you build exactly what you want! Check back again soon for more details on what we'll be releasing..."
            />
            <CpuChipIcon className="mx-auto mt-8 sm:h-48 h-32 w-auto text-white" />
          </span>
        </div>
      </div>
    </UnauthorisedTemplate >
  );
}
