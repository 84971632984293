import UnauthorisedTemplate from "../app/UnauthorisedTemplate";
import PricingTiers from "../pricing/PricingTiers";
import HeroWithText from "../hero/HeroWithText";
import Process from "../process/Process";
import Value from "../value/Value";
import Contact from "../contact/Contact";


export default function Landing() {
  return (
    <UnauthorisedTemplate>

      {/* Hero section */}
      <HeroWithText />

      {/* Process */}
      <Process />

      {/* Value */}
      <Value />

      {/* Pricing */}
      <PricingTiers />

      {/* Contact form */}
      <Contact />

      {/* FAQs */}
      {/* <FAQs /> */}

    </UnauthorisedTemplate>
  );
}
