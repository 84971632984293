import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
} from "react-router-dom";
import { Provider } from "react-redux";

// Redux
import { store } from "./store";
import Landing from "./components/landing/Landing";
import NotFound from "./components/routing/NotFound";
import ProcessPage from "./components/process/ProcessPage";
import ValuePage from "./components/value/ValuePage";
import ContactPage from "./components/contact/ContactPage";
import PricingPage from "./components/pricing/PricingPage";
import AiPage from "./components/ai/AiPage";


function App() {
  return (
    <Provider store={store}>
      <Router>
        <Routes>
          {/* Landing - Public */}
          <Route path="/" element={<Landing />} />

          {/* Process - Public */}
          <Route path="/process" element={<ProcessPage />} />

          {/* Value - Public */}
          <Route path="/value" element={<ValuePage />} />

          {/* Pricing - Public */}
          <Route path="/pricing" element={<PricingPage />} />

          {/* Contact - Public */}
          <Route path="/contact" element={<ContactPage />} />

          {/* AI - Public (including preview of no-code editor) */}
          <Route path="/ai" element={<AiPage />} />

          {/* FAQs - Public */}
          {/* <Route path="/faqs" element={<Login />} /> */}

          {/* Not Found */}
          <Route path="/404" element={<NotFound />} />
          <Route path="*" element={<Navigate replace to="/404" />} />
        </Routes>
      </Router>
    </Provider>
  );
}

export default App;
