import { BanknotesIcon, CheckBadgeIcon, LightBulbIcon, MagnifyingGlassIcon, RocketLaunchIcon, WrenchScrewdriverIcon } from "@heroicons/react/24/solid";
import SectionHeader from "../layout/SectionHeader";
import SimpleCallToAction from "../cta/SimpleCallToAction";

interface Step {
  name: string;
  description: string;
  icon: React.ComponentType<React.SVGProps<SVGSVGElement>>;
  colour: string;
  scale: number;
}

export const processSteps: Step[] = [
  {
    name: "1. Discovery",
    description: "Using our proven prototyping framework you'll have a specialised designer assigned to conduct a discovery session with you.",
    icon: MagnifyingGlassIcon,
    colour: "danger",
    scale: 110,
  },
  {
    name: "2. Build",
    description: "Using our AI automation tools & expertise, we rapidly build your app within a fraction of the traditional time and cost.",
    icon: WrenchScrewdriverIcon,
    colour: "warning",
    scale: 110,
  },
  {
    name: "3. Launch",
    description: "We deploy your app to the cloud, provide you with web access and support your successful launch with any final updates & adjustments needed.",
    icon: RocketLaunchIcon,
    colour: "success",
    scale: 110,
  }
]

export default function Value() {
  return (
    <div className="relative isolate px-6 lg:px-8">
      <div className="mx-auto max-w-3xl">
        <SectionHeader
          alignment="center"
          header="Value"
          subheader="Here's how we add instant value to your business"
        />

        <div className="mx-auto my-16 flex max-w-2xl flex-col gap-8 lg:mx-0 lg:my-20 lg:max-w-none lg:flex-row lg:items-end">

          {/* First value proposition - Speed to market */}
          <div className="group flex flex-col-reverse justify-between gap-x-16 gap-y-8 rounded-2xl bg-light/5 ring-1 ring-inset ring-white/10 transition ease-in-out delay-100 duration-300 hover:bg-black p-8 sm:w-3/4 sm:max-w-md sm:flex-row-reverse sm:items-end lg:w-72 lg:max-w-none lg:flex-none lg:flex-col lg:items-start">
            <p className="flex-none text-3xl font-bold tracking-tight text-white">
              Cost & time saving
              <BanknotesIcon className="flex-none h-14 w-auto text-white group-hover:text-danger mt-4" />
            </p>
            <div className="sm:w-80 sm:shrink lg:w-auto lg:flex-none">
              <p className="text-lg font-semibold tracking-tight text-white">
                {`> 90% reduction in time to market`}
              </p>
              <p className="mt-2 text-base leading-7 text-light">
                Saving you time and money to stay as lean as possible
              </p>
            </div>
          </div>

          {/* Second value proposition - Technical resource */}
          <div className="group flex flex-col-reverse justify-between gap-x-16 gap-y-8 rounded-2xl bg-light/5 ring-1 ring-inset ring-white/10 transition ease-in-out delay-100 duration-300 hover:bg-black p-8 sm:flex-row-reverse sm:items-end lg:w-full lg:max-w-sm lg:flex-auto lg:flex-col lg:items-start lg:gap-y-44">
            <p className="flex-none text-3xl font-bold tracking-tight text-white">
              Technical expertise
              <LightBulbIcon className="flex-none h-14 w-auto text-white group-hover:text-warning mt-4" />
            </p>
            <div className="sm:w-80 sm:shrink lg:w-auto lg:flex-none">
              <p className="text-lg font-semibold tracking-tight text-white">
                Access decades of global technical expertise
              </p>
              <p className="mt-2 text-base leading-7 text-light">
                Providing the latest in AI, ML, and cloud technologies
              </p>
            </div>
          </div>

          {/* Third value proposition - Trusted advisor */}
          <div className="group flex flex-col-reverse justify-between gap-x-16 gap-y-8 rounded-2xl bg-light/5 ring-1 ring-inset ring-white/10 transition ease-in-out delay-100 duration-300 hover:bg-black p-8 sm:w-11/12 sm:max-w-xl sm:flex-row-reverse sm:items-end lg:w-full lg:max-w-none lg:flex-auto lg:flex-col lg:items-start lg:gap-y-28">
            <p className="flex-none text-3xl font-bold tracking-tight text-white">
              A trusted advisor
              <CheckBadgeIcon className="flex-none h-14 w-auto text-white group-hover:text-success mt-4" />
            </p>
            <div className="sm:w-80 sm:shrink lg:w-auto lg:flex-none">
              <p className="text-lg font-semibold tracking-tight text-white">
                Expert advice you can rely on
              </p>
              <p className="mt-2 text-base leading-7 text-light">
                No more guesswork. A proven & local resource to help
              </p>
            </div>
          </div>
        </div>

        <SimpleCallToAction
          title="Still not convinced?"
          subtitle="Take a closer look at our value proposition"
          textColour="dark"
          bgColour="info"
          buttonText="See more"
          buttonLink="/value"
          buttonColour="dark"
          buttonTextColour="white"
        />

      </div>
    </div>
  );
}
