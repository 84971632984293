import UnauthorisedTemplate from "../app/UnauthorisedTemplate";
import SectionHeader from "../layout/SectionHeader";
import ContactButton from "./ContactButton";

export default function ContactPage() {
  return (
    <UnauthorisedTemplate>
      <div className="relative isolate px-6 lg:px-8 pt-32">
        <div className="mx-auto max-w-3xl grid grid-cols-1 sm:gap-y-56 gap-y-40">

          {/* Hero */}
          <span>
            <SectionHeader
              alignment="center"
              header="Get in touch!"
              subheader="If you're ready to start, looking for a quote or have a question we're ready to help."
            />

            <ContactButton />
          </span>

        </div>
      </div>
    </UnauthorisedTemplate>
  );
}
