export default function HeroWithText() {
  return (
    <div className="relative isolate px-6 lg:px-8">
      <div className="mx-auto max-w-2xl pt-32">
        <div className="hidden sm:mb-8 sm:flex sm:justify-center">
          <div className="relative rounded-full px-3 py-1 text-sm leading-6 text-light ring-1 ring-light/30 hover:ring-light/60">
            Take a sneak peek at our AI powered no-code tool, coming soon!{" "}
            <a href="/ai" className="font-semibold text-warning-dark">
              <span className="absolute inset-0" aria-hidden="true" />
              Read more <span aria-hidden="true">&rarr;</span>
            </a>
          </div>
        </div>
        <div className="text-center">
          <h1 className="text-4xl font-bold tracking-tight text-white sm:text-6xl">
            Launch your tech startup in days, not months. Dream big, start now!
          </h1>
          <h3 className="sm:text-6xl text-4xl mt-4">⭐️🚀⭐️</h3>
          <p className="mt-6 text-lg leading-8 text-light">
            We rapidly develop prototypes, MVPs and full-fledged web apps without writing a single line of code at an affordable price.{" "}
          </p>
          <div className="mt-10 flex items-center justify-center gap-x-6">
            <a
              href="/contact"
              className={`rounded-md bg-gradient-to-r from-warning-light via-warning to-warning-dark background-animate px-3.5 py-2.5 text-sm font-semibold text-dark shadow-sm transition ease-in-out delay-100 duration-300 hover:scale-110 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-warning`}
            >
              Get started!
            </a>
            <a href="/value" className="text-sm font-semibold leading-6 text-white">
              Learn more <span aria-hidden="true">→</span>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
